import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Block, BlockSeparator } from "./block-elements";
import styled from "styled-components";
import Logo from "./logo";
import theme from "./theme";

const FooterWrapper = styled.div`
  // background-color: ${theme.color.background_dark};
  width: 100%;
  // padding-top: 72px;
  // padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // margin-top: 6px;
`;

const LogoWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 72px;
`;

// const Copyright = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   font-size: 13px;
//   font-weight: 300;
//   color: rgba(255, 255, 255, 0.3);
// `;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 350;
  color: ${theme.color.text_medium};
  opacity: 0.5;
  background-color: ${theme.color.background};
  padding-bottom: ${theme.spacing.margins};
  padding-top: ${theme.spacing.phone};
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    padding-top: ${theme.spacing.tablet};
  }
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    padding-top: ${theme.spacing.computer};
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 48px;
`;

const ItemColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-left: 48px;
  padding-right: 48px;
  // width: 200px;
`;

const ItemHeading = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 8px;
  padding-top: 8px;
  color: white;
  white-space: nowrap;
  line-height: 1;
`;

const Item = styled(Link)`
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  line-height: 1;
  &:hover{
    color: rgba(255, 255, 255, 0.6);
  }
`;

const ItemSpacer = styled.div`
  height: 30px;
`;

const Tagline = styled.div`
  box-sizing: border-box;
  display: flex;
  color: ${theme.color.text};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 500px;
  font-size: 14px;
  font-weight: 350;
  line-height: 24px;
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
`;

const Footer = (props) => {

  return (
    <FooterWrapper>
      <BlockSeparator shape={3} topColor="white" bottomColor="light" />
      <Block backgroundColor="light">
        <Logo logoHeight="72px" />
        <Tagline>
          We're developing innovative research solutions that leverage artificial intelligence (AI) and machine learning (ML) to streamline the process of European Union (EU) legal research.
        </Tagline>
        <Copyright>
          © 2024 LawLibrary.AI LLC
        </Copyright>
      </Block>
    </FooterWrapper>
    // <FooterWrapper>
    //   <LogoWrapper>
    //     <Logo logoHeight="60px" wordmarkHeight="36px" inverted={true} />
    //   </LogoWrapper>
    //   <ItemWrapper>
    //     <ItemColumn>
    //       <ItemHeading>
    //         US legal research
    //       </ItemHeading>
    //       <Item to="/US-reading-room">
    //         US reading room
    //       </Item>
    //       <Item to="/US-citation-tree">
    //         US citation tree
    //       </Item>
    //       <Item to="/US-AI-assistant">
    //         US AI assistant
    //       </Item>
    //       <ItemSpacer />
    //       <ItemHeading>
    //         US political monitoring
    //       </ItemHeading>
    //       <Item to="/federal-legislation">
    //         Federal legislation
    //       </Item>
    //       <Item to="/federal-regulation">
    //         Federal regulation
    //       </Item>
    //       <Item to="/federal-court-cases">
    //         Federal court cases
    //       </Item>
    //     </ItemColumn>
    //     <ItemColumn>
    //       <ItemHeading>
    //         EU legal research
    //       </ItemHeading>
    //       <Item to="/EU-reading-room">
    //         EU reading room
    //       </Item>
    //       <Item to="/EU-citation-tree">
    //         EU citation tree
    //       </Item>
    //       <Item to="/EU-AI-assistant">
    //         EU AI assistant
    //       </Item>
    //       <ItemSpacer />
    //       <ItemHeading>
    //         EU political monitoring
    //       </ItemHeading>
    //       <Item to="/EU-legislation">
    //         EU legislation
    //       </Item>
    //       <Item to="/CJEU-cases">
    //         CJEU cases
    //       </Item>
    //       <Item to="/EU-state-aid-awards">
    //         EU state aid awards
    //       </Item>
    //       <Item to="/EU-compliance-cases">
    //         EU compliance cases
    //       </Item>
    //     </ItemColumn>
    //     <ItemColumn>
    //       <ItemHeading>
    //         Product
    //       </ItemHeading>
    //       <Item to="/subscribe">
    //         Subscribe
    //       </Item>
    //       <Item to="/sign-up">
    //         Sign up
    //       </Item>
    //       <Item to="/sign-in">
    //         Sign in
    //       </Item>
    //       <ItemSpacer />
    //       <ItemHeading>
    //         Company
    //       </ItemHeading>
    //       <Item to="/about">
    //         About
    //       </Item>
    //       <Item to="/blog">
    //         Blog
    //       </Item>
    //       <Item to="/contact-us">
    //         Contact us
    //       </Item>
    //       <Item to="/terms-of-service">
    //         Terms of service
    //       </Item>
    //       <Item to="/privacy-policy">
    //         Privacy policy
    //       </Item>
    //     </ItemColumn>
    //   </ItemWrapper>
    //   <Copyright>
    //     © 2023 LawLibrary.AI LLC
    //   </Copyright>
    // </FooterWrapper>
  )
}

export default Footer;
