import React from "react";
import styled from "styled-components";
import Logo from "./logo";
import Menu from "./menu";
import theme from "./theme";
import { Button, InternalLinkButton, ExternalLinkButton } from "./button-elements";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 100vw;
`;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  display: none;
  height: calc(74px + 16px);
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    position: ${props => props.fixed ? "fixed" : "relative"};
    top: 0px;
    left: 0px;
    right: 0px;
    height: 96px;
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 96px;
    justify-content: space-between;
    align-items: center;
  }
`;

const HeaderButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  width: 100%;
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    margin-top: 0px;
    width: unset;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const HeaderButtonWrapper = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  @media (min-width: 768px) {
    margin-left: 24px;
    margin-right: 0px;
  }
`;

const Header = (props) => {

  const { userData, signOut, fixed } = props;

  let buttons;

  if (userData) {
    buttons =
      <HeaderButtonGroup>
        <HeaderButtonWrapper>
          <InternalLinkButton primary to="/app">
            Go to app
          </InternalLinkButton>
        </HeaderButtonWrapper>
        <HeaderButtonWrapper>
          <InternalLinkButton to="/account">
            Account
          </InternalLinkButton>
        </HeaderButtonWrapper>
        <HeaderButtonWrapper>
          <Button onClick={signOut}>
            Sign out
          </Button>
        </HeaderButtonWrapper>
      </HeaderButtonGroup>
  } else {
    buttons =
      <HeaderButtonGroup>
        <HeaderButtonWrapper>
          <ExternalLinkButton primary href="mailto:media@lawlibrary.ai">
            Contact us
          </ExternalLinkButton>
        </HeaderButtonWrapper>
        <HeaderButtonWrapper>
          <InternalLinkButton to="/sign-up">
            Sign up
          </InternalLinkButton>
        </HeaderButtonWrapper>
        <HeaderButtonWrapper>
          <InternalLinkButton to="/sign-in">
            Sign in
          </InternalLinkButton>
        </HeaderButtonWrapper>
      </HeaderButtonGroup>
  }

  return (
    <Wrapper>
      <HeaderWrapper fixed={fixed}>
        <HeaderButtonGroup>
          <Logo logoHeight="60px" />
        </HeaderButtonGroup>
        {buttons}
      </HeaderWrapper>
      <Menu userData={userData} signOut={signOut} />
    </Wrapper>
  );
}

export default Header;
