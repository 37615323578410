import styled from "styled-components";
import theme from "./theme.json";

const FormGrid = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 350px;
`;

const FormElement = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  padding-right: 12px;
  padding-left: 12px;
`;

const FormInstructions = styled.div`
  width: 340px;
  font-size: 14px;
  font-weight: 350;
  color: ${theme.color.paragraph};
  line-height: 20px;
  margin-bottom: 16px;
  &::before {
    content: '';
    display: block;
    margin-top: -4px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -4px;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  width: 100%;
`;

const FormMessage = styled.div`
  box-sizing: border-box;
  display: ${props => props.$hidden ? "none" : "flex"};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.color.text};
  width: 340px;
  border-radius: 6px;
  background-color: ${theme.color.background};
  line-height: 1.5;
`;

export { FormGrid, FormElement, FormInstructions, FormMessage, FormRow }; 
