import React, { useState, useEffect, useRef } from "react";
import { Parallax } from "react-scroll-parallax";
import styled from "styled-components";
import TextLoop from "react-text-loop";
import { ExternalLinkButton } from "./button-elements";
import Axios from "axios";
import Menu from "./menu";
import Footer from "./footer";
import theme from "./theme";
import Flowchart from "./flowchart";
import Header from "./header";
import { Paragraph, Heading, Tagline, Highlight } from "./text-elements";
import { Block, BlockSeparator, BlockTitle, BlockParagraph } from "./block-elements";
import { PageWrapper } from "./wrapper-elements";
import { FormGrid, FormRow, FormElement, FormMessage } from "./form-elements";
import { Feature, FeatureGrid } from "./feature-elements";
import TextBox from "./text-box";

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-top: ${theme.spacing.phone};
  margin-bottom: ${theme.spacing.phone};
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    margin-top: ${theme.spacing.tablet};
    margin-bottom: ${theme.spacing.tablet};
  }
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    margin-top: ${theme.spacing.computer};
    margin-bottom: ${theme.spacing.computer};
  }
`;

const WideBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-bottom: ${theme.spacing.text_correction};
`;

const NarrowBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${theme.spacing.text_correction};
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    max-width: 70%; 
  }
`;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const LandingPage = (props) => {

  const { API } = props;

  const [email, setEmail] = React.useState("")
  const [message, setMessage] = React.useState("")

  const width = getWindowDimensions().width;

  const joinWaitlist = () => {
    console.log("join waitlist")
    setMessage("");
    Axios({
      method: "post",
      url: API + "/user/join-waitlist",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "email": email,
      }
    })
      .then(response => {
        // console.log(response.data)
        setEmail("");
        setMessage(response.data.message);
      })
      .catch(error => {
        console.log(error.response.data.error)
        setMessage(error.response.data.error);
      });
  }


  return (
    <PageWrapper>
      <TitleWrapper>
        <BlockTitle>
          An industry-leading database of EU legal documents
        </BlockTitle>
        <BlockParagraph style={{ color: theme.color.paragraph }}>
          At LawLibrary.AI, we want to make EU legal research <Highlight style={{ background: theme.color.primary, color: "white " }}>better for everyone.</Highlight> LawLibrary.AI provides the <Highlight style={{ background: theme.color.primary, color: "white " }}>highest-quality database</Highlight> of EU legal documents on the market. It's cleaner, more complete, and more current than EUR-Lex. If you're using EUR-Lex as your content provider for EU legal documents, you're missing out.
        </BlockParagraph>
        <FormElement>
          <ExternalLinkButton primary href="mailto:sales@lawlibrary.ai">
            Request a demo
          </ExternalLinkButton>
        </FormElement>
      </TitleWrapper>
      <Menu />
      <BlockSeparator shape={3} topColor="white" bottomColor="light" />
      <Block backgroundColor="light">
        <WideBlock>
          <BlockTitle>
            Clean, complete, current
          </BlockTitle>
          <BlockParagraph>
            LawLibrary.AI provides legal tech companies with an <Highlight style={{ background: theme.color.primary, color: "white " }}>industry-leading database</Highlight> of EU legal documents, complete with a bilingual, French-and-English text corpus, vector embeddings, and document- and paragraph-level metadata, and ML-powered annotations, including paragraph-level content tags and actor tags. Our database is clearner, more complete, and more current than EUR-Lex.
          </BlockParagraph>
          <BlockParagraph>
            We offer a <Highlight style={{ background: theme.color.primary, color: "white " }}>subscription-based API</Highlight> that gives you access to our entire database. You can customize your subscription to include our text corpus, vector embeddings, metadata, and AI-powered annotations, depending on your use-case.
          </BlockParagraph>
          <FeatureGrid>
            <Feature
              title="Industry-leading database"
              icon="large-icons/industry-leading-database.svg"
              text="Our industry-leading database of EU legal documents includes a bilingual text corpus, vector embeddings, metadata, and AI-powered annotations. It's complete and always up to date."
              inverted={true}
            />
            <Feature
              title="Bilingual text corpus"
              icon="large-icons/bilingual-text-corpus.svg"
              text="Our text corpus is available in English and French. We provide vector embeddings based on the original French version.  It covers primary law, secondary law, and case law and is always up-to-date."
              inverted={true}
            />
            <Feature
              title="Powerful API"
              icon="large-icons/powerful-API.svg"
              text="Our API makes it easy to access our database. You can do keyword searches using the full text, semantic searches using embeddings, and/or apply filters using document or paragraph metadata."
              inverted={true}
            />
          </FeatureGrid>
        </WideBlock>
      </Block>
      <BlockSeparator shape={4} topColor="light" bottomColor="white" flipY={true} />
      <Block backgroundColor="white">
        <WideBlock color={theme.color.background}>
          <BlockTitle>
            The problem with EUR-Lex
          </BlockTitle>
          <BlockParagraph>
            Look, EUR-Lex is a great resource for legal research, but it has plenty of problems too. You've probably noticed some of them. If you're using EUR-Lex as your content provider for EU legal documents, you're <Highlight style={{ background: theme.color.title, color: "white " }}>missing out</Highlight> on a lot of important information your users may want.
          </BlockParagraph>
          <FeatureGrid>
            <Feature
              title="Missing documents"
              icon="large-icons/missing-data.svg"
              text="EUR-Lex is missing a lot of documents, including over 10,000 court decisions. We compare EUR-Lex and InfoCuria, collect the best version of every document, and parse and clean the text, saving you a lot of time and effort."
            />
            <Feature
              title="Incomplete metadata"
              icon="large-icons/inconsistent-coding.svg"
              text="EUR-Lex's metadata is incomplete. There's a wide variety of errors, inconsistencies, and omissions. The use of content labels are inconsistent. Citation metadata is often incomplete. There are errors in dates, citations, and document numbers. We use a consistent coding protocol and cross-reference sources to identify and correct errors."
            />
            <Feature
              title="No paragraph metadata"
              icon="large-icons/limited-metadata.svg"
              text="EUR-Lex doesn't provide any paragraph metadata, like pinpoint citations for paragraphs or content labels. If you're developing a legal chatbot using an LLM and RAG, and want it to accurately and precisely cite relevant sources, we can provide the fine-grained, paragraph metadata you'll need."
            />
            <Feature
              title="Long delays"
              icon="large-icons/long-delays.svg"
              text="EUR-Lex has all the latest documents, but you'll have to wait on the metadata. It can take months or years for EUR-Lex add citation metadata. We use ML to provide citation metadata instantly."
            />
          </FeatureGrid>
        </WideBlock>
      </Block>
      <BlockSeparator shape={4} topColor="white" bottomColor="light" flipY={true} />
      <Block backgroundColor="light">
        <WideBlock color={theme.color.background}>
          <BlockTitle>
            Our solution
          </BlockTitle>
          <BlockParagraph>
            LawLibrary.AI leverages advances in ML to create a <Highlight style={{ background: theme.color.primary, color: "white " }}>clean, complete, current database</Highlight> of EU legal documents. Our database covers primary law (treates), secondary law (directives and regulations), and case law (judgments, orders, AG opinions). It's available in French and English, and it's available through an easy-to-use API.
          </BlockParagraph>
          <FeatureGrid>
            <Feature
              title="Advanced data pipeline"
              icon="large-icons/data-pipeline.svg"
              text="Our end-to-end data pipeline collects legal documents and selects the best versions, cleans and parses the text of documents, tracks changes over time and creates consolidated versions, and cross-references and corrects metadata."
              inverted={true}
            />
            <Feature
              title="ML models"
              icon="large-icons/ML-models.svg"
              text="Our state-of-the-art machine-learning models can extract citations, code paragraph-level labels, and tag political and legal actors. We can fine-tune our classification models to code custom labels for your users."
              inverted={true}
            />
          </FeatureGrid>
        </WideBlock>
      </Block>
      <BlockSeparator shape={3} topColor="light" bottomColor="white" flipY={true} />
      <Block backgroundColor="white">
        <WideBlock color={theme.color.background}>
          <BlockTitle>
            Why LawLibrary.AI?
          </BlockTitle>
          <BlockParagraph>
            LawLibrary.AI has the proprietary data, machine-learning models, and domain expertise to help your company improve your coverage of EU law.
          </BlockParagraph>
          <FeatureGrid>
            <Feature
              title="Better data"
              icon="large-icons/better-data.svg"
              text="We have better data than any other content provider. We've digitized and cleaned old documents, parsed documents into a paragraph-level corpus, coded missing metadata, and applied ML-powered labels."
            />
            <Feature
              title="Cutting-edge models"
              icon="large-icons/cutting-edge-models.svg"
              text="We have cutting-edge machine learning models that can accurately annotate legal documents. We use fine-tuned large language models (LLMs) to embed documents, extract citations, code custom content labels, and tag actors."
            />
            <Feature
              title="Deep expertise"
              icon="large-icons/deep-expertise.svg"
              text="We have deep expertise in EU legal documents and EU legal procedures. We've created a well-designed database that's easy to integrate into your existing data pipelines."
            />
          </FeatureGrid>
        </WideBlock>
      </Block>
    </PageWrapper>
  )
}

export default LandingPage;
