import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import theme from "./theme";
import HeaderLogo from "./header-logo";
import { Button, ButtonRow, ButtonWrapperRightAligned } from "./button-elements";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LeftMenu = styled.div`
  position: fixed;
  z-index: 1;
  top: calc(74px + 74px + 2px);
  bottom: calc(74px + 1px);
  left: 0;
  width: 250px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: 1.5px solid ${theme.color.line};
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px ${theme.color.background};
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const RightMenu = styled.div`
  position: fixed;
  z-index: 1;
  top: calc(74px + 16px + 74px + 16px);
  bottom: calc(24px + 74px + 16px);
  right: 24px;
  width: 250px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1.5px solid ${theme.color.line};
  background: white;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px ${theme.color.background};
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const BottomMenu = styled.div`
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  bottom: 24px;
  left: 24px;
  right: 24px;
  height: 74px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 48px;
  padding-right: 48px;
  background-color: ${theme.color.white};
  border: 1.5px solid ${theme.color.line};
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px ${theme.color.background};
`;

const TopMenu = styled.div`
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  top: calc(74px + 16px);
  left: 24px;
  right: 24px;
  height: 74px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  background-color: white;
  border: 1.5px solid ${theme.color.line};
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px ${theme.color.background};
`;

const Page = styled.div`
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  top: calc(74px + 16px + 74px + 16px);
  bottom: calc(24px + 74px + 16px);
  left: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  scrollbar-width: none;
  border: 1.5px solid ${theme.color.line};
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px ${theme.color.background};
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default function PageTemplate(props) {

  const { pageContent, leftMenuContent, rightMenuContent, bottomMenuContent, topMenuContent } = props;

  let page;
  let bottomMenu;
  let topMenu;
  let leftMenu;
  let rightMenu;

  const calculateLeftMargin = () => {
    let margin = "24px";
    if (leftMenuContent) {
      margin = "calc(250px + 24px + 16px)";
    }
    return (margin);
  }

  const calculateRightMargin = () => {
    let margin = "24px";
    if (rightMenuContent) {
      margin = "calc(250px + 24px + 16px)";
    }
    return (margin);
  }

  if (bottomMenuContent) {
    bottomMenu =
      <BottomMenu>
        {bottomMenuContent}
      </BottomMenu>
  }

  if (topMenuContent) {
    topMenu =
      <TopMenu>
        {topMenuContent}
      </TopMenu>
  }

  if (leftMenuContent) {
    leftMenu =
      <LeftMenu>
        {leftMenuContent}
      </LeftMenu>
  }

  if (rightMenuContent) {
    rightMenu =
      <RightMenu>
        {rightMenuContent}
      </RightMenu>
  }

  if (pageContent) {
    page =
      <Page
        style={{
          left: calculateLeftMargin(),
          right: calculateRightMargin(),
        }}
      >
        {pageContent}
      </Page>
  }

  return (
    <Wrapper>
      {page}
      {bottomMenu}
      {topMenu}
      {leftMenu}
      {rightMenu}
    </Wrapper>
  );
};
