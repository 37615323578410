import styled from "styled-components";
import theme from "./theme";

const BlockInner = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  padding-top: 24px;
  padding-bottom: 24px;
  @media (min-width: 768px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const BlockTitle = styled.div`
  width: 50%;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.3;
  color: ${theme.color.text};
  margin-bottom: ${theme.spacing.text};
  width: calc(100% - 48px);
  @media (min-width: 768px) {
    font-size: 36px;
    max-width: 700px;
    margin-bottom: 24px;
  }
`;

const BlockParagraph = styled.div`
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 350;
  line-height: 28px;
  color: ${theme.color.text};
  margin-bottom: ${theme.spacing.text};
  width: calc(100% - 48px);
  @media (min-width: ${theme.breakpoints.tablet}) {
    // font-size: 18px;
    max-width: 700px;
  }
  // &::before {
  //   content: '';
  //   display: block;
  //   margin-top: -8px;
  // }
  // &::after {
  //   content: '';
  //   display: block;
  //   margin-bottom: -5px;
  // }
`;

const SeparatorWrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${props => props.bottomColor};
  transform: ${props => props.scale};
  margin-top: -8px;
  margin-bottom: -8px;
`;

const Block = (props) => {

  let { backgroundColor } = props;
  let color = "black";


  if (backgroundColor === "white") {
    backgroundColor = "white";
    color = theme.color.paragraph;
  }

  if (backgroundColor === "light") {
    backgroundColor = theme.color.background;
    color = theme.color.paragraph;
  }

  if (backgroundColor === "dark") {
    backgroundColor = theme.color.background_dark;
    color = "white";
  }

  if (backgroundColor === "primary") {
    backgroundColor = theme.color.primary;
    color = "white";
  }

  return (
    <BlockInner backgroundColor={backgroundColor} color={color}>
      {props.children}
    </BlockInner>
  )
}

const BlockSeparator = (props) => {

  let { shape, topColor, bottomColor, flipX, flipY } = props;

  if (flipX === undefined) {
    flipX = false;
  }

  if (flipY === undefined) {
    flipY = false;
  }

  var scale = "scale(1, 1)";

  if (flipX) {
    scale = "scale(-1, 1)";
  }

  if (flipY) {
    scale = "scale(1, -1)";
  }

  if (flipX && flipY) {
    scale = "scale(-1, -1)";
  }

  if (topColor === "light") {
    topColor = theme.color.background;
  }

  if (topColor === "dark") {
    topColor = theme.color.background_dark;
  }

  if (topColor === "primary") {
    topColor = theme.color.primary;
  }

  if (bottomColor === "light") {
    bottomColor = theme.color.background;
  }

  if (bottomColor === "dark") {
    bottomColor = theme.color.background_dark;
  }

  if (bottomColor === "primary") {
    bottomColor = theme.color.primary;
  }
  
  if (flipY) {
    let temp = topColor;
    topColor = bottomColor;
    bottomColor = temp;
  }

  let d;

  if (shape === 1) {
    d = "M 0 0 H 1024 V 128 H 0 V 0 Z";
  }

  if (shape === 2) {
    d = "M 0 0 L 1024 0 L 1024 96 L 0 160 L 0 0 Z";
  }

  if (shape === 3) {
    d = "M 0 0 L 1024 0 L 1024 128 C 1024 128 768 192 512 128 C 256 64 0 128 0 128 L 0 0 Z";
  }

  if (shape === 4) {
    d = "M 0 0 L 1024 0 L 1024 96 C 1024 96 768 160 512 160 C 256 160 0 96 0 96 Z";
  }

  if (shape === 5) {
    d = "M 0 0 L 1024 0 L 1024 70.282 C 887.741 144.423 676.788 192 440 192 C 275.371 192 123.232 169.002 0 130.068 Z";
  }

  return (
    <SeparatorWrapper bottomColor={bottomColor} scale={scale} >
      <svg width="100%" height="100%" viewBox="0 0 1024 256">
        <path d={d} style={{ "fill": topColor }} />
      </svg>
    </SeparatorWrapper>
  );
};

export { Block, BlockTitle, BlockParagraph, BlockSeparator };
