import React from "react";
import styled from "styled-components";
import Page from "./page";
import { Button, ButtonWrapperRightAligned } from "./button-elements";
import HeaderLogo from "./header-logo";
import DocumentParagraph from "./document-paragraph";
import SearchPage from "./search-page";
import PageTemplate from "./page-template";
import { IconButton, IconToggleButton } from "./icon-button";
import theme from "./theme";
import { Tooltip } from "react-tooltip";

import {
  PencilSquareIcon as NoteIcon,
  MagnifyingGlassIcon as SearchIcon,
  ArrowDownTrayIcon as DownloadIcon,
  AdjustmentsHorizontalIcon as SettingsIcon,
  MapIcon as OutlineIcon,
  BookmarkIcon as BookmarkIcon,
  ChevronUpIcon as ToTopIcon,
  TagIcon as TagIcon,
  ClipboardIcon as CopyIcon,
  LanguageIcon as LanguageIcon,
  FlagIcon as ReportErrorIcon,
  InformationCircleIcon as InfoIcon,
  ChatBubbleBottomCenterTextIcon as ChatIcon,
  QuestionMarkCircleIcon as HelpIcon,
  ShareIcon as ConnectionsIcon,
  BookOpenIcon as CitationIcon,
  LinkIcon as LinkIcon,
  ChartBarIcon as DataIcon,
  Bars3Icon as MenuIcon,
} from "@heroicons/react/24/outline";

import data from "./example-directive";

const VerticalMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const TopMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
`;

const BottomMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
`;

const Document = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 850px;
  padding-bottom: 50vh;
  padding-top: 96px;
  padding-right: 16px;
  margin-left: -16px;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 16px;
  padding-left: 16px;
  border-left: ${props => props.selected ? "1.5px solid " + theme.color.line_dark : "1.5px solid " + theme.color.background};
  cursor: pointer;
`;

const SubItemWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 16px;
  padding-left: 16px;
  border-left: ${props => props.selected ? "1.5px solid " + theme.color.line_dark : "1.5px solid " + theme.color.background};
  cursor: pointer;
`;

const Indicator = styled.div`
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  color: ${theme.color.primary};
  margin-left: 16px;
`;

const Number = styled.span`
  font-size: 16px;
  font-weight: 400;
  margin-right: 8px;
  min-width: 42px;
`;

const ParagraphWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 16px;
`;

const ParagraphInnerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Paragraph = styled.div`
  // display: flex;
  // flex-direction: row;
  font-size: 16px;
  font-weight: 400;
  flex-grow: 1;
  // width: 100%;
  color: ${theme.color.paragraph};
  line-height: 1.5;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: ${theme.border_radius.box};
  background-color: ${theme.color.background};
  border: ${props => props.selected ? "1.5px solid " + theme.color.line_dark : "1.5px solid " + theme.color.background};
`;

const Section = styled.div`
  display: flex;
`;

; const Divider = styled.div`
  border-right: 1.5px solid ${theme.color.line};
  height: 42px;
  margin-left: 8px;
  margin-right: 8px;
`;

const DocumentNameWrapper = styled.span`

`;

const DocumentName = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

const SelectedItem = styled.span`
  font-size: 16px;
  font-weight; 400;
  margin-left: 24px;
  line-height: 1;
`;

const SubItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: calc(42px + 16px + 16px);
`;

const SubItemLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const TagRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -8px;
`;

const Tag = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-right: 8px;
  margin-bottom: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  color: ${theme.color.paragraph};
  background-color: white;
  border: 1.5px solid ${theme.color.line_dark};
  border-radius: 16px;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: ${theme.color.paragraph};
    border: 1.5px solid ${theme.color.paragraph};
  }
`;

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Note = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
  border: 1.5px solid ${theme.color.line};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  // color: ${theme.color.paragraph};
  color: rgba(40, 40, 45, 0.6);
`;

const MapWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-right: 32px;
  padding-left: 16px;
`;

const MapTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 1;
  margin-left: 16px;
`;

const MapHeading = styled.div`
  display: flex;
  min-width: 100%;
  min-height: 8px;
  margin-top: 4px;
  background-color: ${theme.color.background};
  border-radius: 4px;
  cursor: pointer;
`;

const MapParagraph = styled.div`
  display: flex;
  min-width: 100%;
  min-height: 16px;
  margin-top: 4px;
  background-color: ${theme.color.background};
  border-radius: 4px;
  cursor: pointer;
`;

const MapItemWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  width: calc(100% - 8px);
  flex-direction: column;
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1.5px solid ${theme.color.line};
`;

const Relevance = styled.div`
  // box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  min-width: 42px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  margin-left: 16px;
  background-color: ${theme.color.primary};
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.5);
`;

const parseItems = (data, settings, parentSelected, callback) => {

  let content = [];

  data.map((data_i, i) => {
    if (data_i.type === "heading" || data_i.type === "paragraph") {
      content.push(
        <Item i={i} data={data_i} settings={settings} parentSelected={parentSelected} callback={callback} />
      )
    }
  })

  return (
    content
  );
};

const Item = (props) => {

  const { i, data, settings, parentSelected, callback } = props;

  const [isSelected, setIsSelected] = React.useState(false);

  let children;
  if (data.children) {
    children =
      parseItems(data.children, settings, parentSelected || isSelected, callback);
  }

  let citations;
  if (data.citations && settings.citationsOn) {
    citations =
      <SubItem>
        <SubItemLabel>
          Citations
        </SubItemLabel>
        <TagRow>
          {data.citations.map((tag, i) => {
            return (
              <Tag key={i}>
                {tag}
              </Tag>
            )
          })}
        </TagRow>
      </SubItem>
  }

  let tags;
  if (data.tags && settings.tagsOn) {
    tags =
      <SubItem>
        <SubItemLabel>
          Tags
        </SubItemLabel>
        <TagRow>
          {data.tags.map((tag, i) => {
            return (
              <Tag key={i}>
                {tag}
              </Tag>
            )
          })}
        </TagRow>
      </SubItem>
  }

  let notes;
  if (data.notes && settings.notesOn) {
    notes =
      <SubItem>
        <SubItemLabel>
          Notes
        </SubItemLabel>
        <NotesWrapper>
          {data.notes.map((note, i) => {
            return (
              <Note key={i}>
                {note}
              </Note>
            )
          })}
        </NotesWrapper>
      </SubItem>
  }

  let number;
  if (data.number) {
    number = data.number
  }

  let color = "white";
  let opacity = 1;
  let score = "";
  if (data.relevance == 3) {
    color = theme.color.primary;
    opacity = 0.9;
    score = "9";
  };
  if (data.relevance == 2) {
    color = theme.color.primary;
    opacity = 0.6;
    score = "8";
  };
  if (data.relevance == 1) {
    color = theme.color.primary;
    opacity = 0.3;
    score = "7";
  };

  let paragraph;
  if (data.type === "heading") {
    paragraph =
      <ParagraphInnerWrapper>
        <Paragraph selected={isSelected || parentSelected}>
          <Heading>
            {settings.selectedLanguage === "english" ? data.english[0] : data.french[0]}
          </Heading>
        </Paragraph>
        <Relevance
          style={{
            backgroundColor: color,
            opacity: opacity
          }}
        >
          {score}
        </Relevance>
      </ParagraphInnerWrapper>
  }
  if (data.type === "paragraph") {
    paragraph =
      <ParagraphInnerWrapper>
        <Paragraph selected={isSelected || parentSelected}>
          <Number>{number}</Number> <span>{settings.selectedLanguage === "english" ? data.english[0] : data.french[0]}
          </span>
        </Paragraph>
        <Relevance
          style={{
            backgroundColor: color,
            opacity: opacity
          }}
        >
          {score}
        </Relevance>
      </ParagraphInnerWrapper>
  }

  let subitem;
  if (children) {
    subitem =
      <SubItemWrapper
        selected={parentSelected || isSelected}
      >
        {citations}
        {tags}
        {notes}
      </SubItemWrapper>
  } else {
    subitem =
      <>
        {citations}
        {tags}
        {notes}
      </>
  }

  return (
    <ItemWrapper
      id={data.label}
      key={i}
      style={{ borderLeft: data.level === 1 ? "none" : "" }}
      selected={parentSelected}
    >
      <ParagraphWrapper
        onMouseEnter={() => {
          callback(data.label);
          setIsSelected(true);
        }}
        onMouseLeave={() => {
          callback("");
          setIsSelected(false);
        }}
      >
        {paragraph}
        {subitem}
      </ParagraphWrapper>
      {children}
    </ItemWrapper>
  );
};

const parseMapItems = (data, settings, parentSelected, callback) => {

  let content = [];

  data.map((data_i, i) => {
    if (data_i.type === "heading" || data_i.type === "paragraph") {
      content.push(
        <MapItem i={i} data={data_i} settings={settings} parentSelected={parentSelected} callback={callback} />
      )
    }
  })

  return (
    content
  );
};

const MapItem = (props) => {

  const { i, data, settings, parentSelected, callback } = props;

  const [isSelected, setIsSelected] = React.useState(false);

  const height = data.english[0].split(" ").length * 0.5;

  let color = theme.color.background;
  let opacity = 1;
  if (data.relevance == 3) {
    color = theme.color.primary;
    opacity = 0.9;
  };
  if (data.relevance == 2) {
    color = theme.color.primary;
    opacity = 0.6;
  };
  if (data.relevance == 1) {
    color = theme.color.primary;
    opacity = 0.3;
  };

  let children;
  if (data.children) {
    children =
      parseMapItems(data.children, settings, parentSelected || isSelected, callback);
  }

  let paragraph;
  if (data.type === "heading") {
    paragraph =
      <MapHeading
        id={data.label}
        selected={isSelected || parentSelected}
        onClick={() => {
          document.getElementById(data.label).scrollIntoView({ top: 0, behavior: "smooth" });
        }}
      />
  }
  if (data.type === "paragraph") {
    paragraph =
      <MapParagraph
        id={data.label}
        selected={isSelected || parentSelected}
        onClick={() => {
          document.getElementById(data.label).scrollIntoView({ top: 0, behavior: "smooth" });
        }}
        style={{
          height: height,
          backgroundColor: color,
          opacity: opacity
        }}
      />
  }

  return (
    <MapItemWrapper
      key={i}
      style={{ borderLeft: data.level === 1 ? "none" : "" }}
      selected={parentSelected}
      onMouseEnter={() => {
        callback(data.label);
        setIsSelected(true);
      }}
      onMouseLeave={() => {
        callback("");
        setIsSelected(false);
      }}
    >
      {paragraph}
      {children}
    </MapItemWrapper>
  );
};

const AppPage = (props) => {

  // const { data } = props;

  const [selectedLanguage, setSelectedLanguage] = React.useState("english");
  const [outlineOn, setOutlineOn] = React.useState(false);
  const [citationsOn, setCitationsOn] = React.useState(false);
  const [tagsOn, setTagsOn] = React.useState(false);
  const [notesOn, setNotesOn] = React.useState(false);
  const [connectionsOn, setConnectionsOn] = React.useState(false);
  const [selectedLabel, setSelectedLabel] = React.useState("");

  const settings = {
    citationsOn: citationsOn,
    tagsOn: tagsOn,
    notesOn: notesOn,
    connectionsOn: connectionsOn,
    selectedLanguage: selectedLanguage
  }

  let topMenuContent =
    <TopMenuWrapper>
      <DocumentNameWrapper>
        <DocumentName>
          {data[0].title}
        </DocumentName>
        <SelectedItem>
          {selectedLabel}
        </SelectedItem>
      </DocumentNameWrapper>
      <Section>
        <Section>
          <IconButton
            id="outline-button"
            tooltipLocation="bottom"
            tooltipContent="Outline"
            value={outlineOn}
            onClick={() => setOutlineOn(!outlineOn)}
          >
            <OutlineIcon />
          </IconButton>
          <IconButton
            id="metadata-button"
            tooltipLocation="bottom"
            tooltipContent="Metadata"
          >
            <DataIcon />
          </IconButton>
        </Section>
        <Divider />
        <Section>
          <IconButton
            id="search-button"
            tooltipLocation="bottom"
            tooltipContent="Search">
            <SearchIcon />
          </IconButton>
          <IconButton
            id="settings-button"
            tooltipLocation="bottom"
            tooltipContent="Settings"
          >
            <SettingsIcon />
          </IconButton>
        </Section>
      </Section >
    </TopMenuWrapper >

  let bottomMenuContent =
    <BottomMenuWrapper>
      <Section>
        <Section>
          <IconButton
            id="english-button"
            value={selectedLanguage === "english"}
            onClick={() => {
              setSelectedLanguage("english")
            }}
            tooltipLocation="top"
            tooltipContent="English"
          >
            EN
          </IconButton>
          <IconButton
            id="french-button"
            value={selectedLanguage === "french"}
            onClick={() => {
              setSelectedLanguage("french")
            }}
            tooltipLocation="top"
            tooltipContent="French"
          >
            FR
          </IconButton>
        </Section>
        <Divider />
        <Section>
          <IconButton
            id="citations-button"
            value={citationsOn}
            onClick={() => setCitationsOn(!citationsOn)}
            tooltipLocation="top"
            tooltipContent="Citations"
          >
            <CitationIcon />
          </IconButton>
          <IconButton
            id="tags-button"
            value={tagsOn}
            onClick={() => setTagsOn(!tagsOn)}
            tooltipLocation="top"
            tooltipContent="Tags"
          >
            <TagIcon />
          </IconButton>
          <IconButton
            id="notes-button"
            value={notesOn}
            onClick={() => setNotesOn(!notesOn)}
            tooltipLocation="top"
            tooltipContent="Notes"
          >
            <NoteIcon />
          </IconButton>
          <IconButton
            id="connections-button"
            value={connectionsOn}
            onClick={() => setConnectionsOn(!connectionsOn)}
            tooltipLocation="top"
            tooltipContent="Connections"
          >
            <ConnectionsIcon />
          </IconButton>
        </Section>
        <Divider />
        <Section>
          <IconButton
            id="download-button"
            onClick={() => { }}
            tooltipLocation="top"
            tooltipContent="Download"
          >
            <DownloadIcon />
          </IconButton>
        </Section>
        <Divider />
        <Section>
          <IconButton
            id="scroll-to-top-button"
            onClick={() => {
              document.getElementById("document-wrapper").scrollIntoView({ top: 0, behavior: "smooth" });
            }}
            tooltipLocation="top"
            tooltipContent="Scroll to top"
          >
            <ToTopIcon />
          </IconButton>
        </Section>
      </Section>
    </BottomMenuWrapper>

  let leftMenuContent;

  let rightMenuContent;
  if (outlineOn) {
    rightMenuContent =
      <MapWrapper>
        <MapTitle>
          Outline
        </MapTitle>
        {parseMapItems(data, settings, false, setSelectedLabel)}
      </MapWrapper>
  }

  let pageContent =
    <Document id="document-wrapper">
      {parseItems(data, settings, false, setSelectedLabel)}
    </Document>

  const page =
    <PageTemplate
      pageContent={pageContent}
      topMenuContent={topMenuContent}
      bottomMenuContent={bottomMenuContent}
      leftMenuContent={leftMenuContent}
      rightMenuContent={rightMenuContent}
    />

  return (
    page
  )
}

export default AppPage;
