import styled from "styled-components";
import theme from "./theme";
import { Link } from "react-router-dom";

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonWrapperRightAligned = styled.div`
  margin-left: 16px;
`;

const ButtonWrapperLeftAligned = styled.div`
  margin-right: 16px;
`;

const ButtonWrapperCenterAligned = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 1;
  color: ${props => props.primary ? theme.color.white : theme.color.text} !important;
  background-color: ${props => props.primary ? theme.color.button_primary : theme.color.button} !important;
  border-radius: 32px;
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
  cursor: pointer;
  transition-duration: 0.2s;
  text-decoration: none !important;
  white-space: nowrap;
  &:hover {
    background-color: ${props => props.primary ? theme.color.button_primary_hover : theme.color.button_hover} !important;
  }
`;

const InternalLinkButton = styled(Link)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 1;
  color: ${props => props.primary ? theme.color.white : theme.color.text} !important;
  background-color: ${props => props.primary ? theme.color.button_primary : theme.color.button} !important;
  border-radius: 32px;
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
  cursor: pointer;
  transition-duration: 0.2s;
  text-decoration: none !important;
  white-space: nowrap;
  &:hover {
    background-color: ${props => props.primary ? theme.color.button_primary_hover : theme.color.button_hover} !important;
  }
`;

const InternalLinkButtonWhite = styled(Link)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 1;
  color: ${theme.color.primary};
  background-color: white;
  border-radius: 32px;
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
  cursor: pointer;
  transition-duration: 0.2s;
  text-decoration: none !important;
  white-space: nowrap;
  &:hover {
    opacity: 0.8;
  }
`;

const ExternalLinkButton = styled.a`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 1;
  color: ${props => props.primary ? theme.color.white : theme.color.text} !important;
  background-color: ${props => props.primary ? theme.color.button_primary : theme.color.button} !important;
  border-radius: 32px;
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
  cursor: pointer;
  transition-duration: 0.2s;
  text-decoration: none !important;
  white-space: nowrap;
  &:hover {
    background-color: ${props => props.primary ? theme.color.button_primary_hover : theme.color.button_hover} !important;
  }
`;

export { Button, InternalLinkButton, ExternalLinkButton, ButtonWrapperRightAligned, ButtonWrapperLeftAligned, ButtonWrapperCenterAligned, ButtonRow, ButtonGroup, InternalLinkButtonWhite };
