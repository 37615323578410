import React from "react";
import Axios from "axios";
import styled from "styled-components";
import PageTemplate from "./page-template";
import { Button } from "./button-elements";
import ChatSystem from "./chat-system";
import { IconButton } from "./icon-button";

import {
  PencilSquareIcon as NoteIcon,
  MagnifyingGlassIcon as SearchIcon,
  ArrowDownTrayIcon as DownloadIcon,
  AdjustmentsHorizontalIcon as SettingsIcon,
  MapIcon as OutlineIcon,
  BookmarkIcon as BookmarkIcon,
  ChevronUpIcon as ToTopIcon,
  TagIcon as TagIcon,
  ClipboardIcon as CopyIcon,
  LanguageIcon as LanguageIcon,
  FlagIcon as ReportErrorIcon,
  InformationCircleIcon as InfoIcon,
  ChatBubbleBottomCenterTextIcon as ChatIcon,
  QuestionMarkCircleIcon as HelpIcon,
  ShareIcon as ConnectionsIcon,
  BookOpenIcon as CitationIcon,
  LinkIcon as LinkIcon,
  ChartBarIcon as DataIcon,
  Bars3Icon as MenuIcon,
} from "@heroicons/react/24/outline";

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
`;

const ChatbotWrapper = styled.div`
  position: fixed;
  top: 160px;
  left: 250px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
`;

const ChatbotQuestion = styled.textarea`
  box-sizing: border-box;
  resize: none;
  width: 600px;
  height: 100px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: black;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  &:hover {
    border: 1.5px solid black;
  }
  &:focus {
    border: 1.5px solid black;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`;

const ChatbotSendButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 16px;
  margin-bottom: 32px;
  width: 600px;
  margin-right: -16px;
`;

const ChatbotAnswer = styled.div`
  width: 600px;
  height: 350px;
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-y: scroll;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
`;

const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 32px;
  margin-right: 32px;
`;

const Section = styled.div`
  display: flex;
`;

export default function TestPage(props) {

  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");

  const askQuestion = () => {
    setAnswer("");
    console.log(question)
    Axios({
      method: "post",
      url: "http://localhost:4000/chatbot",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "question": question,
      }
    })
      .then(response => {
        console.log(response.data.answer)
        setAnswer(response.data.answer);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response.data.error);
        }
      });
  }

  const bottomMenuContent =
    <div>
      <Button onClick={() => {
        setAnswer("");
        setQuestion("");
      }}>
        Clear chat
      </Button>
    </div>

  const topMenuContent =
    <TopMenu>
      <Title>
        AI legal assistant
      </Title>
      <Section>
        <IconButton>
          <ChatIcon />
        </IconButton>
        <IconButton>
          <CopyIcon />
        </IconButton>
        <IconButton>
          <DownloadIcon />
        </IconButton>
        <IconButton>
          <SettingsIcon />
        </IconButton>
      </Section>
    </TopMenu>

  let answerBox;

  if (answer !== "") {
    answerBox =
      <ChatbotAnswer>
        {answer}
      </ChatbotAnswer>
  }

  // const pageContent =
  //   <ChatbotWrapper>
  //     <ChatbotQuestion value={question} onChange={(e) => setQuestion(e.target.value)}>

  //     </ChatbotQuestion>
  //     <ChatbotSendButtonWrapper>
  //       <Button onClick={() => askQuestion(question)}>
  //         Ask question
  //       </Button>
  //     </ChatbotSendButtonWrapper>
  //     {answerBox}
  //   </ChatbotWrapper>

  const pageContent =
    <ChatSystem />

  return (
    <PageTemplate topMenuContent={topMenuContent} pageContent={pageContent} />
  );
};
