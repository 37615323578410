import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import theme from "./theme";

const ButtonWrapper = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${props => props.value ? theme.color.background_dark : "white"};
  color: ${props => props.value ? "white" : theme.color.text};
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: ${props => props.value ? theme.color.background_dark_hover : theme.color.background};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  font-size: 14px;
`;

const IconButton = (props) => {

  const { id, value, onClick, tooltipContent, tooltipLocation } = props;

  return (
    <ButtonWrapper>
      <Button
        value={value}
        onClick={onClick}
        data-tooltip-id={id}
        data-tooltip-delay-show={1500}
        data-tooltip-delay-hide={0}
        data-tooltip-offset={28}
      >
        <IconWrapper>
          {props.children}
        </IconWrapper>
      </Button>
      <Tooltip
        id={id}
        place={tooltipLocation}
        content={tooltipContent}
        style={{ 
          backgroundColor: "rgba(40, 40, 45, 1)",
          opacity: 1,
          zIndex: 3000,
          position: "absolute"
        }}
      />
    </ButtonWrapper>
  )
}

export { IconButton };
