import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "./button-elements";
import theme from "./theme.json";

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-left: 24px;
  width: 100%;
  height: 96px;
    @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    height: 128px;
  }
`;

const Icon = styled.img`
  height: 100%;
  // opacity: 0.3;
`;

const Photo = styled.img`
  height: 100%;
  border-radius: 50%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
`;

const Tag = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: white;
  line-height: 1.3;
  padding-bottom: 12px;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

const SmallButtonWrapper = styled.div`
  margin-top: 24px;
  margin-left: 24px;
`;

const FeatureContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 24px;
`;

const FeatureColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const LineGroupHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 48px;
  margin-left: 12px;
  margin-right: 12px;
  width: 100%;
`;

const LineLightHorizontal = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  flex-grow: 1;
`;

const LineDarkHorizontal = styled.div`
  border-top: 1px solid ${theme.color.line_dark};
  width: 36px;
`;

const LineGapHorizontal = styled.div`
  width: 12px;
`;

const LineLightVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // border-left: 1px solid rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  // padding-left: 24px;
`;

const LineDarkVertical = styled.div`
  // border-left: 1px solid ${theme.color.line_dark};
  // padding-left: 24px;
  // margin-bottom: 12px;
`;

const FeatureSpacer = styled.div`
  min-width: 0px;
  max-width: 0px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    min-width: 300px;
    max-width: 900px;
  }
`;

const FeatureTitle = styled.div`
  font-weight: 600;
  color: ${theme.color.text};
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 20px;
`;

const FeatureText = styled.p`
  width: 100%;
  font-weight: 350;
  line-height: 24px;
  color: ${theme.color.text_medium};
  font-size: 15px;
  &::before {
    content: '';
    display: block;
    margin-top: -6px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
`;

const FeatureGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: calc(100% - 48px);
  margin-top: 24px;
  // margin-bottom: 24px;
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    // margin-top: 48px;
    // margin-bottom: 48px;
  }
`;

const FeatureWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
  padding-top: 48px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 16px;
  background: ${ props => props.$inverted ? "white" : theme.color.background};
  // border: 1.5px solid ${theme.color.primary};
  box-shadow: ${ props => props.$inverted ? "0px 0px 30px 0px rgba(0, 0, 0, 0.05)" : "none"};
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    width: 325px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const FeatureDivider = (props) => {
  return (
    <div>
      <LineGroupHorizontal>
        <LineDarkHorizontal />
        <LineGapHorizontal />
        <LineLightHorizontal />
      </LineGroupHorizontal>
    </div>
  );
}

const LargeFeature = (props) => {

  const { title, text, tag, icon, buttonLabel, buttonLink, inverted } = props;

  let iconComponent;
  if (icon) {
    iconComponent =
      <div>
        <IconWrapper>
          <Icon src={icon} />
        </IconWrapper>
      </div>
  }

  let buttonComponent;
  if (buttonLabel) {
    buttonComponent =
      <ButtonWrapper>
        <Button style="outlined" corners="pill" inverted={inverted} label={buttonLabel} to={buttonLink} />
      </ButtonWrapper>
  }

  let titleComponent;
  if (title) {
    titleComponent =
      <FeatureTitle $inverted={inverted} >
        {title}
      </FeatureTitle>
  }

  let tagComponent;
  if (tag) {
    tagComponent =
      <Tag>
        {tag}
      </Tag>
  }

  const paragraphs = [].concat(text);

  let textComponent;
  if (text) {
    textComponent =
      <FeatureColumn>
        {paragraphs.map((paragraph, i) => {
          return (
            <FeatureText key={i}>{paragraph}</FeatureText>
          )
        })}
      </FeatureColumn>
  }

  return (
    <div>
      {iconComponent}
      <div>
        <FeatureContentWrapper>
          {tagComponent}
          {titleComponent}
          {textComponent}
          {buttonComponent}
        </FeatureContentWrapper>
      </div>
    </div>
  )
}

const Feature = (props) => {

  const { title, text, tag, icon, buttonLabel, buttonLink, inverted } = props;

  let iconComponent;
  if (icon) {
    iconComponent =
      <IconWrapper>
        <Icon src={icon} />
      </IconWrapper>
  } else {
    iconComponent =
      <FeatureSpacer />
  }

  let buttonComponent;
  if (buttonLabel) {
    buttonComponent =
      <SmallButtonWrapper>
        <Button style="outlined" corners="pill" small={true} inverted={inverted} label={buttonLabel} to={buttonLink} />
      </SmallButtonWrapper>
  }

  let titleComponent;
  if (title) {
    titleComponent =
      <LineDarkVertical>
        <FeatureTitle $inverted={inverted} >
          {title}
        </FeatureTitle>
      </LineDarkVertical>
  }

  let tagComponent;
  if (tag) {
    tagComponent =
      <Tag>
        {tag}
      </Tag>
  }

  const paragraphs = [].concat(text);

  let textComponent;
  if (text) {
    textComponent =
      <LineLightVertical>
        {paragraphs.map((paragraph, i) => {
          return (
            <FeatureText key={i}>{paragraph}</FeatureText>
          )
        })}
      </LineLightVertical>
  }

  return (
    <FeatureWrapper $inverted={inverted}>
      {iconComponent}
      <FeatureContentWrapper>
        {tagComponent}
        {titleComponent}
        {textComponent}
        {buttonComponent}
      </FeatureContentWrapper>
    </FeatureWrapper>
  )
}

const BioFeature = (props) => {

  const { title, text, icon } = props;

  let iconComponent;
  if (icon) {
    iconComponent =
      <IconWrapper>
        <Photo src={icon} />
      </IconWrapper>
  } else {
    iconComponent =
      <FeatureSpacer />
  }

  let titleComponent;
  if (title) {
    titleComponent =
      <LineDarkVertical>
        <FeatureTitle>
          {title}
        </FeatureTitle>
      </LineDarkVertical>
  }

  const paragraphs = [].concat(text);

  let textComponent;
  if (text) {
    textComponent =
      <LineLightVertical>
        {paragraphs.map((paragraph, i) => {
          return (
            <FeatureText key={i}>{paragraph}</FeatureText>
          )
        })}
      </LineLightVertical>
  }

  return (
    <FeatureWrapper>
      {iconComponent}
      <FeatureContentWrapper>
        {titleComponent}
        {textComponent}
      </FeatureContentWrapper>
    </FeatureWrapper>
  )
}

export { LargeFeature, Feature, FeatureDivider, FeatureSpacer, FeatureTitle, FeatureText, Icon, FeatureGrid, BioFeature };
