import React from "react";
import styled from "styled-components";
import theme from "./theme";

const Button = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 3000;
`;

const Icon = styled.div`
  width: 28px;
  height: 22px;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
  & span:nth-child(1) {
    top: 0px;
    width: ${props => props.open ? "0%" : "100%"};
    left: ${props => props.open ? "50%" : "0%"};
    background-color: ${theme.color.line_dark};
  }
  & span:nth-child(2) {
    top: 10px;
    transform: ${props => props.open ? "rotate(45deg)" : "rotate(0deg)"};
    background-color: ${theme.color.line_dark};
  }
  & span:nth-child(3) {
    top: 10px;
    transform: ${props => props.open ? "rotate(-45deg)" : "rotate(0deg)"};
    background-color: ${theme.color.line_dark};
  }
  & span:nth-child(4) {
    top: 20px;
    width: ${props => props.open ? "0%" : "100%"};
    left: ${props => props.open ? "50%" : "0%"};
    background-color: ${theme.color.line_dark};
  }
`;

const Line = styled.span`
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
`;

const MenuButton = (props) => {
  const { open, onClick } = props;
  return (
    <Button onClick={onClick}>
      <Icon open={open}>
        <Line />
        <Line />
        <Line />
        <Line />
      </Icon>
    </Button>
  );
}

export default MenuButton;
